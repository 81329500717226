.Title {
    font-family: 'Source Sans Pro';
    font-size: 25pt;
    font-weight: 900;
    color: black;
    letter-spacing: 0.3px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.Body {
    font-family: 'Source Sans Pro';
    font-weight: 500;
    color: black;
    margin: 0px;
}

.DetailLabel {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    color: #666;
    font-size: 14px;
}

.DetailValue {
    font-family: 'Source Sans Pro';
    font-size: 16px;
}

.Bold {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: black;
    margin: 0px;
}

.Semibold {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    color: black;
    margin: 0px;
}

.Underlined {
    font-family: 'Source Sans Pro';
    font-weight: 500;
    color: black;
    margin: 0px;
    text-decoration: underline;
}

.BodyLarge {
    font-family: 'Source Sans Pro';
    font-weight: 500;
    color: black;
    margin: 0px;
    font-size: large;
}

.BoldLarge {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: black;
    margin: 0px;
    font-size: large;
}

.SemiboldLarge {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    color: black;
    margin: 0px;
    font-size: large;
}

.UnderlinedLarge {
    font-family: 'Source Sans Pro';
    font-weight: 500;
    color: black;
    margin: 0px;
    font-size: large;
    text-decoration: underline;
}

/* White Variations */

.BodyWhite {
    font-family: 'Source Sans Pro';
    font-weight: 500;
    color: white;
    margin: 0px;
}

.BoldWhite {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
}

.SemiboldWhite {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    color: white;
    margin: 0px;
}

.UnderlinedWhite {
    font-family: 'Source Sans Pro';
    font-weight: 500;
    color: white;
    margin: 0px;
    text-decoration: underline;
}

/* Pills */

.BoldPillOutline {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: black;
    margin: 0px;
    padding: 0px 12px;
    border: 2px solid black;
    border-radius: 30px;
}

.BoldPillLight {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: black;
    margin: 0px;
    padding: 0px 12px;
    background-color: rgb(235, 235, 245);
    border-radius: 30px;
}

.BoldPillDark {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: black;
    border-radius: 30px;
}

.BoldPillGreen {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #0f9346;
    border-radius: 30px;
}

.BoldPillGreenLight {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    background-color: #e4fff0;
    margin: 0px;
    padding: 0px 12px;
    color: #0f9346;
    border-radius: 30px;
}

.BoldPillBlueLight {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    background-color: #dce3ff;
    margin: 0px;
    padding: 0px 12px;
    color: #1e3799;
    border-radius: 30px;
}

.BoldPillPurpleLight {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    background-color: #e1daff;
    margin: 0px;
    padding: 0px 12px;
    color: #341f97;
    border-radius: 30px;
}

.BoldPillRed {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #e74c3c;
    border-radius: 30px;
}

.BoldPillRainbow {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), teal), linear-gradient(to right, rgba(255, 0, 100, .3), rgba(255, 100, 127, .2)), linear-gradient(to top right, red, rgba(0, 0, 0, 0)), radial-gradient(closest-corner at 20% 80%, yellow, blue);
    border-radius: 30px;
}



/* Square Pills */


.BoldSquarePillOutline {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: black;
    margin: 0px;
    padding: 0px 12px;
    border: 2px solid black;
    border-radius: 3px;
}

.BoldSquarePillLight {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: black;
    margin: 0px;
    padding: 0px 12px;
    background-color: rgb(235, 235, 245);
    border-radius: 3px;
}

.BoldSquarePillDark {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: black;
    border-radius: 3px;
}

.BoldSquarePillGreen {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #0f9346;
    border-radius: 3px;
}

.BoldSquarePillRed {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #e74c3c;
    border-radius: 3px;
}