.FooterColumn {
    width: 25%;
    display: flex;
    flex-direction: column;
}

.SectionHeader {
    font-weight: bold;
    margin-bottom: 8px;
}

.ColumnFlex {
    display: flex;
    width: 100%;
    gap: 16px;
}

@media (max-width: 800px) {
    .ColumnFlex {
        flex-direction: column;
        gap: 32px !important;
    }

    .FooterColumn {
        width: 100%;
    }
}