.JumbotronBackground {
    width: 100%;
    background-image: radial-gradient(circle, #ccc 1px, transparent 2px);
    background-size: 40px 40px;
    background-repeat: repeat;
    /* background-color: rgb(235, 235, 245); */
}

.Jumbotron {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* height: 500px; */
    padding: 16px 0px;
}

.JumboTronImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.DualPane {
    display: flex;
    width: 100%;
    margin: 16px 0px;
    justify-content: space-around;
}

.Panel {
    width: calc(50% - 16px);
    /* border-radius: 12px; */
    /* background: linear-gradient(0deg, black, #101010); */
    min-height: 320px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Source Sans Pro';
    position: relative;
    padding: 12px;

    border: 1px solid rgb(235, 235, 245);
    background-color: rgb(250, 250, 255);
}

.ResourcesDiv {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.Banner {
    font-family: 'Source Sans Pro';
    font-size: 36pt;
    font-weight: 800;
    letter-spacing: 0.1px;
    margin: 0px auto 0px auto;
    text-align: center;
    background-color: white;
    display: inline-block;
    z-index: 100;
}

.SubBanner {
    font-family: 'Source Sans Pro';
    font-size: 24pt;
    font-weight: 800;
    letter-spacing: 0.1px;
    line-height: 38pt;
    margin: 0 auto;
    text-align: center;
    background-color: white;
    display: inline-block;
}

.CardContainer {
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
}

.CategoryCard {
    position: relative;
    padding: 0px;
    border-radius: 12px;
    width: 340px;
    border: 2px solid;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.CategoryCard:hover {
    cursor: pointer;
    transform: translateY(-4px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.CardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}


@media (max-width: 991px) {
    .Banner {
        font-size: 24pt;
        line-height: 28pt;
    }

    .SubBanner {
        font-size: 19pt;
    }

    .Jumbotron {
        padding: 0px;
    }

    .MainImage {
        width: 375px;
    }

    .DualPane {
        flex-direction: column;
        gap: 16px;
    }

    .LeftJumbotronPanel {
        min-width: 400px;
        width: 400px;
    }

    .Panel {
        width: 100%;
    }

    .TestimonialCard {
        width: 100% !important;
    }

    .Quote {
        font-size: 9pt;
    }
}

.TimelineCard {
    width: 300px;
    height: 100px;
    border-radius: 5px;

    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */
    /* background-color: rgba(255, 255, 255, 0.71); */

    font-family: 'Source Sans Pro';
    padding: 4px 10px;
    /* border: 1px solid rgba(220, 220, 220, 0.71); */
    border: 2px solid black;
    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); */
}

.TimelineNumber {
    width: 32px;
    height: 32px;
    font-weight: bold;
    border-radius: 30px;
    padding-top: 4px;
    color: rgb(90, 52, 150);
    text-align: center;
    margin-bottom: 0px;
}

.TimelineTitle {
    font-weight: 700;
    margin: 0px;
    text-transform: uppercase;
    font-size: 11pt;
    color: black;
}

.PanelTitle {
    font-size: xx-large;
    font-weight: 700;
    margin-bottom: 4px;
}

.PanelSubtitle {
    margin-bottom: 4px;
}

.ResourceBullet {
    margin: 0;
    font-weight: 600;
    color: black;
    padding: 0px 4px;
}

.Screenshot {
    width: 260px;
    border-radius: 12px 12px 0px 0px;
}

.CourseIconBullet {
    height: 24px;
    width: 24px;
    border-width: 0px;
    border-radius: 5px;
    padding: 4px;
}

.TestimonialBullet {
    height: 20px;
    width: 20px;
    border-width: 0px;
    border-radius: 3px;
    padding: 3px;
}

.TestimonialGallery {
    display: flex;
    justify-content: space-around;

}


.AccessButton:hover {
    transform: scale(1.03);
    opacity: 0.9;
    transition: 0.5 transform;
}

.Image3DWhiteShadow {
    box-shadow: 0px 20px 32px rgba(255, 255, 255, 0.3);

    border-width: 0px !important;
    border-radius: 12px;
    width: 100%;
}

.Image3DWhiteShadow:hover {
    transition: transform 1s ease-in-out, background-color 0.3s ease-in-out;
    transform: scale(1.02);
}

.GrowOpacityHover:hover {
    transition: transform 0.25s ease-in-out;
    transform: scale(1.02);
}

/* MOBILE */
@media (max-width: 850px) {
    .Jumbotron {
        flex-direction: column;
    }

    .LeftJumbotronPanel {
        width: 100%;
        min-width: 0px !important;
        text-align: center;
        min-height: 0px;
    }

    .CategoryCard {
        width: 100%;
    }

    .JumbotronTextPanel {
        width: 100%;
    }

    .Banner {
        font-size: 18.5pt;
        margin-bottom: -4px !important;
    }

    .SubBanner {
        font-size: 17pt;
    }

    .TopSpacer48Mobile0 {
        height: 8px;
    }

    .ProductGalleryFlex {
        gap: 0px;
    }
}

.ApproachTitle {
    height: 100px;
    width: 170px;
    display: flex;
    /* border: 1px solid white; */
    padding: 8px;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 4px;
}

.MarketplaceGrid {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
}

@keyframes swooshInOut {
    0% {
        transform: scaleX(0);
        transform-origin: left;
    }

    45% {
        transform: scaleX(1);
        transform-origin: left;
    }

    55% {
        transform: scaleX(1);
        transform-origin: right;
    }

    100% {
        transform: scaleX(0);
        transform-origin: right;
    }
}

@keyframes swooshInDelayed {
    0% {
        opacity: 0;
        transform: scaleX(0);
        transform-origin: left;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
    }
}

@keyframes textColorChange {

    0%,
    85% {
        color: #2980b9;
    }

    100% {
        color: #212529;
    }
}



.blueBackgroundAnim {
    animation: swooshInOut 2s ease-in-out forwards;
}

.greenBackgroundAnim {
    opacity: 0;
    animation: swooshInDelayed 1.2s ease-out forwards;
    animation-delay: 2s;
}

.gotInText {
    animation: textColorChange 2s ease-in-out forwards;
}