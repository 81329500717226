.JumbotronBackground {
    width: 100%;
    background-image: radial-gradient(circle, #ccc 1px, transparent 2px);
    background-size: 40px 40px;
    background-repeat: repeat;
}

.Jumbotron {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* height: 500px; */
    padding: 16px 0px;
}

.LeftJumbotronPanel {
    min-width: 640px;
    width: 640px;
    height: 100%;
    min-height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
}

.RightJumbotronPanel {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.JumboTronImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.DualPane {
    display: flex;
    width: 100%;
    margin: 16px 0px;
    justify-content: space-around;
}

.Panel {
    width: calc(50% - 16px);
    /* border-radius: 12px; */
    /* background: linear-gradient(0deg, black, #101010); */
    min-height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Source Sans Pro';
    position: relative;
    padding: 16px;
    border-radius: 5px;

    border: 1px solid rgb(235, 235, 245);
    background-color: rgb(250, 250, 255);

    overflow: clip;
}

.ResourcesDiv {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.MainImage {
    width: 550px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
    border: 0.5px solid rgb(215, 215, 225);
    margin: 16px;
}

@media (max-width: 1200px) {

    .MainImage {
        width: 425px;
        margin-top: 0px;
    }

    .LeftJumbotronPanel {
        min-width: 500px;
        width: 500px;
    }
}

@media (max-width: 991px) {
    .Jumbotron {
        padding: 0px;
    }

    .MainImage {
        width: 375px;
    }

    .DualPane {
        flex-direction: column;
        gap: 16px;
    }

    .LeftJumbotronPanel {
        min-width: 400px;
        width: 400px;
    }

    .Panel {
        width: 100%;
    }

    .TestimonialCard {
        width: 100% !important;
    }

    .Quote {
        font-size: 9pt;
    }
}

@media (min-width: 991px) and (max-width: 1199px) {
    .Screenshot {
        width: 220px !important;
    }
}

@media (max-width: 579px) {
    .Screenshot {
        width: calc(100% + 24px) !important;
        margin-bottom: 12px;
    }

    .MainImage {
        width: 300px;
    }
}

@media (max-width: 579px) {
    .ResourcesDiv {
        flex-direction: column;
    }
}


.TimelineCard {
    width: 300px;
    height: 100px;
    border-radius: 5px;

    /* backdrop-filter: blur(5px); */
    /* -webkit-backdrop-filter: blur(5px); */
    /* background-color: rgba(255, 255, 255, 0.71); */

    font-family: 'Source Sans Pro';
    padding: 4px 10px;
    /* border: 1px solid rgba(220, 220, 220, 0.71); */
    border: 2px solid black;
    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); */
}

.TimelineNumber {
    width: 32px;
    height: 32px;
    font-weight: bold;
    border-radius: 30px;
    padding-top: 4px;
    color: rgb(90, 52, 150);
    text-align: center;
    margin-bottom: 0px;
}

.TimelineTitle {
    font-weight: 700;
    margin: 0px;
    text-transform: uppercase;
    font-size: 11pt;
    color: black;
}

.PanelTitle {
    font-size: xx-large;
    font-weight: 700;
    margin-bottom: 4px;
}

.PanelSubtitle {
    margin-bottom: 4px;
}

.ResourceBullet {
    margin: 0;
    font-weight: 600;
    color: black;
    padding: 0px 4px;
}

.Screenshot {
    width: 260px;
    border-radius: 12px 12px 0px 0px;
}

.CourseIconBullet {
    height: 24px;
    width: 24px;
    border-width: 0px;
    border-radius: 5px;
    padding: 4px;
}

.TestimonialBullet {
    height: 20px;
    width: 20px;
    border-width: 0px;
    border-radius: 3px;
    padding: 3px;
}

.TestimonialCard {
    width: 400px;
    padding: 16px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    font-family: 'Source Sans Pro';
    padding-bottom: 68px;
}

.TestimonialName {
    font-weight: 600;
    margin: 0;
}

.TestimonialInstitution {
    font-weight: 500;
    margin: 0;
}

.Quote {
    color: #282828;
    text-align: left;
    font-size: medium;
}

/* MOBILE */
@media (max-width: 850px) {
    .Jumbotron {
        flex-direction: column;
    }

    .JumbotronTextPanel {
        width: 100%;
    }

    .JumbotronImage {
        width: 100%;
        margin: 24px 0px;
    }

    .DualView {
        transform: scale(0.78);
    }

    .CardView {
        margin-left: -180px !important;
    }
}

.TagSelectionSlimGrey {
    /* background-color: rgb(225, 225, 235); */
    /* border: 2px solid black; */
    background: linear-gradient(45deg, rgb(240, 240, 240), rgb(250, 250, 255));
    background: linear-gradient(45deg, #000, #333);
    border-radius: 5px;
    /* border: 1px solid rgb(215, 215, 225); */
    display: flex;
    gap: 8px;
    padding: 4px 16px;
    font-family: 'Source Sans Pro';
    align-items: center;
    font-weight: 600;
}

.TagSelectionSlimImage {
    height: 18px;
    width: 18px;
}

.TagSelectionSlimGrey:hover {
    cursor: pointer;
    opacity: 0.8;

}

.UserCount {
    /* background-color: #5a97ec44;
    color: #1e3799; */
    background-color: rgb(235, 235, 245);
    color: #282828;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    width: min-content;
    white-space: nowrap;
    padding: 0px 12px;
    border-radius: 25px;
    margin-bottom: 16px;
    margin-top: 0px;
}

.CourseColorDiv {
    padding: 12px;
    width: 190px;
    height: 38px;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@media (min-width: 850px) {

    .CourseColorDiv:hover {
        cursor: pointer;
        transition: transform 0.25s ease-in-out;
        transform: scale(1.02);
    }


}

.CourseColorDivTitle {
    color: white;
    font-family: 'Source Sans Pro';
    font-size: 11.5pt;
    margin: 0;
    font-weight: 600;
    text-align: left;
    line-height: 1em;
}

.CourseColorDivIcon {
    height: 20px;
    width: 20px;
}

@media (max-width: 850px) {
    .UserCount {
        margin-bottom: 24px;
        margin-top: -4px;
    }
}