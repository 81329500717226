.Banner {
    font-family: 'Source Sans Pro';
    font-size: 38pt;
    font-weight: 700;
    letter-spacing: 0.1px;
    padding-bottom: 0px;
    line-height: 44pt;
    margin-bottom: 0pt;
    text-align: center;
}

.SubBannerHome {
    font-family: 'Source Sans Pro';
    font-size: 28pt;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding-bottom: 0px;
    text-align: center;
    /* line-height: 44pt; */
    margin-bottom: 0px;
    text-transform: uppercase;
}

.SubBanner {
    font-family: 'Source Sans Pro';
    letter-spacing: 0.4px;
    font-size: 15.5pt !important;
}

.Title {
    font-family: 'Source Sans Pro';
    font-size: 25pt;
    font-weight: 900;
    /* color: rgb(56, 56, 56); */
    color: black;
    letter-spacing: 0.3px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    /* border-left: 4px solid rgb(56, 56, 56); */
    /* padding-left: 8px; */
}

.TitleWhite {
    font-family: 'Source Sans Pro';
    font-size: 25pt;
    font-weight: 900;
    color: white;
    letter-spacing: 0.3px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.Body {
    font-family: 'Source Sans Pro';
    letter-spacing: 0.4px;
    color: rgb(70, 70, 70);
}

.BodyWhite {
    font-family: 'Source Sans Pro';
    letter-spacing: 0.4px;
    color: white;
}

.H6 {
    font-family: 'Source Sans Pro';
    font-size: 13pt;
    font-weight: 900;
    color: rgb(49, 49, 49);
    letter-spacing: 0.4px;
    padding-bottom: 0px;
    margin-bottom: 4px;
}


@media (max-width: 600px) {
    .Question {
        font-size: 14pt;
    }
}

.RecordingBarText {
    font-family: 'Source Sans Pro';
    font-size: medium;
    font-weight: 600;
    margin: 0px 8px;
    text-align: center;
    width: 31%;
}

.RecordingBarTextButton {
    font-family: 'Source Sans Pro';
    font-size: medium;
    font-weight: 600;
    margin: 0px 8px;
    text-align: center;
    width: 31%;
    border-radius: 5px;
    background-color: #1e3799;
    padding: 4px 0px;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.RecordingBarTextButton:hover {
    cursor: pointer;
    background-color: #485eb6;
}

.WhiteBullet {
    font-weight: bold;
    text-align: center;
    font-size: large;
    padding: 6px 8px;
    border-radius: 20px;
    font-family: "Source Sans Pro";
    color: rgb(21, 27, 44);
    background-color: white;
    margin: 4px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1000px) {
    .Banner {
        font-size: 22pt !important;
        line-height: 30pt;
    }

    .SubBanner {
        font-size: 12pt !important;
    }

    .TitleWhite {
        font-size: 28pt !important;
    }

    .SubBannerHome {
        margin-top: -8px;
        font-size: 14pt !important;
        text-transform: uppercase;
    }
}

.TOS {
    font-family: 'Source Sans Pro';
    letter-spacing: 0.4px;
    color: rgb(70, 70, 70);
    cursor: pointer;
    margin: 0;
}

.TOS:hover {
    opacity: 0.8;
}

.QuestionTitle {
    font-family: 'Source Sans Pro';
    font-size: 16pt;
    font-weight: 500;
    color: rgb(56, 56, 56);
    letter-spacing: 0.3px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    text-align: center;
}

@media (max-width: 768px) {
    .QuestionTitle {
        font-size: 14pt;
    }
}

@media (max-width: 500px) {
    .QuestionTitle {
        font-size: 13pt;
    }
}