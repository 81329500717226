.SchoolLink {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-size: 14pt;
    color: black;
    /* text-decoration: underline; */
    margin-bottom: 2px;
}

.SchoolLink:hover {
    opacity: 0.8;
    cursor: pointer;
}

.Card {
    border: 1px solid rgb(225, 225, 235);
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 6px;
    background-color: white;
    ;
}

.Card:hover {
    border: 1px solid rgb(150, 150, 160);
    cursor: pointer;
}

.Pill {
    margin: 0px;
    font-weight: 600;
    background-color: rgb(235, 235, 245);
    border-radius: 20px;
    padding: 0px 12px;
    color: black;
}

@media (max-width: 768px) {
    .SearchBar {
        margin-top: 8px;
    }
}