.StandardLight {
    background-color: rgb(235, 235, 245);
    color: black;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    border: 0px;
    border-radius: 30px;
    padding: 4px 16px;
}

.StandardLight:hover {
    cursor: pointer;
    color: #333;
}

.StandardWhite {
    background-color: white;
    color: black;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    border: 0px;
    border-radius: 30px;
    padding: 4px 16px;
}

.StandardWhite:hover {
    cursor: pointer;
    color: #333;
    background-color: #ffffffc3;
}


.WhiteOutline {
    background-color: black;
    color: white;
    /* transition: all 0.2s ease; */
    border: 1px solid white;
    border-radius: 100px;
    padding: 0px 8px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
}

.WhiteOutline:hover {
    border-width: 2px;
    font-weight: 700;
}

.StandardWhiteShadow {
    background-color: white;
    color: black;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    border: 0px;
    transition: all 0.2s ease;
    border-radius: 30px;
    padding: 4px 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.StandardWhiteShadow:hover {
    cursor: pointer;
    color: #333;
    background-color: #ffffffc3;
    transform: translateY(-1px);
}

.StandardRed {
    background-color: rgb(237, 0, 0);
    color: white;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    border: 0px;
    border-radius: 30px;
    padding: 4px 16px;
}

.StandardRed:hover {
    cursor: pointer;
    background-color: rgb(255, 55, 55);
}


.StandardDark {
    background-color: black;
    color: white;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    border: 0px;
    border-radius: 30px;
    padding: 4px 16px;
}

.StandardDark:hover {
    cursor: pointer;
    background-color: #333;

}

.StandardGrey {
    background-color: #404040;
    color: white;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    border: 0px;
    border-radius: 30px;
    padding: 4px 16px;
}

.StandardGrey:hover {
    cursor: pointer;
    background-color: black;
}

.LowKey {
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Source Sans Pro';
    font-weight: 600;
    border: 0px;
    border-radius: 3px;
    padding: 4px 16px;
    background-color: rgba(255, 255, 255, 0.5);
    /* background-color: transparent; */
}

.LowKey:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
}

/* Modern Button */

.ModernButton {
    background-color: transparent;
    border: none;
    font-weight: 600;
    color: #0a70d6;
    width: min-content;
    white-space: nowrap;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.ModernButton:hover {
    opacity: 0.85;
    cursor: pointer;
    text-decoration: underline;
}

.ActionButtonSlimBlue {
    border: 8px;
    font-weight: 600;
    color: #1e3799;
    background-color: transparent;
    border-radius: 20px;
    white-space: nowrap;
    padding: 2px 12px;
    margin: 8px 0px;
    width: min-content;
    background-color: #1e379922;
}

.ActionButtonSlimBlue:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
}