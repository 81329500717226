.RightPane {
    padding: 32px;
    position: absolute;
    display: flex;
    width: calc(100% - 180px);
    margin: 0px 0px 0px 180px;
    height: 100%;
    background-color: rgb(223, 228, 234);
    overflow-y: scroll;
}

.DynamicPane {
    background-color: white;
    border-radius: 5px;
    margin: 12px 0px 12px 0px;
    padding: 16px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.DynamicPaneClickable {
    background-color: white;
    border-radius: 12px;
    margin: 12px 0px 12px 0px;
    padding: 16px;
    width: 100%;
}

.DynamicPaneClickable:hover {
    opacity: 0.67;
}

.ModernButton {
    background-color: transparent;
    border: none;
    font-weight: 600;
    color: rgb(10, 112, 214);
    width: min-content;
    white-space: nowrap;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.ModernButton:hover {
    opacity: 0.85;
    cursor: pointer;
    text-decoration: underline;
}

.ActionButton {
    background-color: #1e3799;
    color: white;
    /* font-family: 'Source Sans Pro'; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-weight: bold;
    font-size: 15pt;
    padding: 8px 16px 8px 16px;
    border-radius: 40px;
    border: none;
    margin-bottom: 8px;
    text-decoration: none;
    cursor: pointer;
    width: min-content;
    white-space: nowrap;
}

.ActionButton:hover {
    opacity: 0.65;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(1.04);
}

.TrendyButton {
    color: white;
    background-color: #1e3799;
    padding: 16px;
    font-size: 16pt;
    font-weight: 600;
    border: none;
    border-radius: 50px;
    box-shadow: 0 8px 16px #1e379986;
    font-family: 'Source Sans Pro';
}

.TrendyButton:hover {
    opacity: 0.85;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(1.04);
    cursor: pointer;
}

.TrendyButtonWhiteBorder {
    background-color: white;
    color: #1e3799;
    padding: 16px;
    font-size: 16pt;
    font-weight: 600;
    border-radius: 50px;
    font-family: 'Source Sans Pro';
    border: 3px solid #1e3799;
}

.TrendyButtonWhiteBorder:hover {
    background-color: #1e379914;
    transition: transform 0.3s ease-in-out;
    transform: scale(1.04);
    cursor: pointer;
}

.ActionButtonWhite {
    color: #1e3799 !important;
    background-color: white !important;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    font-size: 15pt;
    padding: 8px 16px 8px 16px;
    border-radius: 5px;
    border: none;
    margin-bottom: 8px;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.ActionButtonWhite:hover {
    opacity: 0.65;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(1.04);
}


.ImageCenteredBG {
    position: absolute;
    height: 100%;
    width: 75%;
    background-position: 0px 0px;
    background-image:
        /* Original Gradients */
        radial-gradient(70% 53% at 36% 76%, #0A003EFF 0%, #073AFF00 100%),
        radial-gradient(42% 53% at 15% 94%, #092952FF 7%, #073AFF00 100%),
        radial-gradient(18% 28% at 35% 87%, #FFFFFFFF 7%, #073AFF00 100%),
        radial-gradient(31% 43% at 7% 98%, #FFFFFFFF 24%, #073AFF00 100%),
        radial-gradient(21% 37% at 72% 23%, #3A72CEFF 24%, #073AFF00 100%),
        radial-gradient(35% 56% at 91% 74%, #8A4FFFF5 9%, #073AFF00 100%),
        radial-gradient(74% 86% at 67% 38%, #1e3799 24%, #073AFF00 100%),
        linear-gradient(125deg, #0E1859FF 1%, #040D52FF 100%);
    background-size: 200% 200%;
    animation: GradientBackground 20s ease infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    z-index: -100;
}

@keyframes GradientBackground {
    0% {
        background-position: 0% 50%;
        background-size: 150% 150%;
    }

    25% {
        background-position: 100% 50%;
        background-size: 150% 150%;
    }

    50% {
        background-position: 50% 100%;
        background-size: 250% 250%;
    }

    75% {
        background-position: 0% 100%;
        background-size: 150% 150%;
    }

    100% {
        background-position: 0% 50%;
        background-size: 200% 200%;
    }
}



.LeftFloatingPane {
    /* background-color: #141e2a; */
    position: absolute;
    background-color: white;
    padding: 32px;
    width: 30%;
    min-width: 350px;
    height: 100vh;
    left: 0 !important;
    box-shadow: 15px 4px 12px rgba(0, 0, 0, 0.1);
}

.DarkInput {
    background-color: #202a37 !important;
    font-family: 'Source Sans Pro';
    letter-spacing: 0.4px;
    color: white !important;
    border: 1px solid #09111b !important;
}

.FlexMobileFriendly {
    display: flex;
    width: '100%';
    justify-content: space-between;
}

.MiniIcon {
    height: 100%;
    margin-right: 16px;
}

.DynamicPaneNonStretch {
    background-color: white;
    border-radius: 5px;
    margin: 4px 8px;
    height: 54px;
    display: 'flex';
    align-items: center;
    padding: 10px 14px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Card {
    border: 1px solid rgb(231, 231, 231);
    background-color: white;
    min-width: 220px;
    width: 300px;
    display: flex;
    border-radius: 12px;
    padding: 16px 8px;
    flex-direction: column;
    align-items: center;
    height: 190px;
    color: rgb(56, 56, 56);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 8px;
}

.Card:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.03);
}


.Uni {
    height: 150px;
    margin: 4px 8px;
}

@media (max-width: 1000px) {

    .Card {
        margin: 4px;
        height: 240px
    }
}

@media (max-width: 850px) {

    .FlexMobileFriendly {
        width: '100%';
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MobileExpand100 {
        width: 100% !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .DisappearMobile {
        display: none;
    }

    .FlexReverse800 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Card {
        margin: 4px;
        height: 180px !important;
    }

    .Uni {
        height: 100px;
    }
}

@media (max-width: 600px) {
    .UniGallery {
        flex-direction: column;
    }

    .LeftFloatingPane {
        min-width: 100%;
    }
}

.HighlightOnHover:hover {
    /* background-color: rgb(235, 235, 245); */
    font-weight: bold;
    border: '1px solid black'
}

/* Buttons */

.ActionButtonSlim {
    background-color: #1e3799;
    color: white;
    /* font-family: 'Source Sans Pro'; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-weight: bold;
    font-size: 13pt;
    padding: 3px 12px 3px 12px;
    border-radius: 5px;
    border: none;
    margin-bottom: 8px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 100px;
    white-space: nowrap;
    width: min-content;
}

.ActionButtonSlim:hover {
    opacity: 0.65;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(1.04);
}

.ActionButtonSlimGrey {
    background-color: rgb(235, 235, 245);
    color: black;
    /* font-family: 'Source Sans Pro'; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-weight: bold;
    font-size: 13pt;
    padding: 3px 12px 3px 12px;
    border-radius: 5px;
    border: none;
    margin-bottom: 8px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 100px;
    width: min-content;
    white-space: nowrap;
}

.ActionButtonSlimGrey:hover {
    opacity: 0.65;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(1.04);
}

.ActionButtonSlimBlue {
    border: 8px;
    font-weight: 600;
    color: #1e3799;
    background-color: transparent;
    border-radius: 20px;
    white-space: nowrap;
    padding: 2px 12px;
    margin: 8px 0px;
    width: min-content;
    background-color: #1e379922;
}

.ActionButtonSlimBlue:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
}

/* Input Fields */

.ModernInputField {
    background-color: rgb(245, 245, 255);
    border-radius: 5px;
    border: 1px solid rgb(225, 225, 235);
    padding: 4px;
}

.ModernInputFieldWhite {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(225, 225, 235);
    padding: 4px;
}


.ModernInputFieldLabel {
    font-size: small;
    text-transform: uppercase;
    font-weight: bold;
    /* margin-left: 4px; */
    margin-bottom: 2px;
}

/* Segmented View */

.SegmentedControlBackground {
    height: 40px;
    background-color: rgb(235, 235, 245);
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 5px;
    width: min-content;
}

.SegmentedControlBackgroundThin {
    height: 32px;
    background-color: rgb(235, 235, 245);
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 5px;
    width: min-content;
}

.SegmentedControlBackgroundVertical {
    background-color: rgb(235, 235, 245);
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 5px;
    width: min-content;
    flex-direction: column;

}

.SegmentedControl {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    padding: 5px 12px;
    margin: 0;
    white-space: nowrap;

    border-radius: 30px;

    color: rgb(79, 79, 79);
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: medium;
    font-weight: 600;
}

.SegmentedControl:hover {
    cursor: pointer;
    color: #282828;
    background-color: transparent;
}

.SegmentedControlSelected {
    display: flex;
    align-items: center;
    border: 0px;
    white-space: nowrap;
    justify-content: center;

    padding: 5px 12px;
    margin: 0;

    border-radius: 30px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    color: black;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: medium;
    font-weight: 600;
}

.SegmentedControlThin {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    padding: 2px 12px;
    margin: 0;
    white-space: nowrap;

    border-radius: 30px;

    color: rgb(79, 79, 79);
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 11.5pt;
    font-weight: 600;
}

.SegmentedControlThin:hover {
    cursor: pointer;
    color: #282828;
    background-color: transparent;
}

.SegmentedControlSelectedThin {
    display: flex;
    align-items: center;
    border: 0px;
    white-space: nowrap;
    justify-content: center;

    padding: 2px 12px;
    margin: 0;

    border-radius: 30px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    color: black;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 11.5pt;
    font-weight: 600;
}

/* Spinner */

.Spinner {
    border: 10px solid transparent;
    border-top: 10px solid #1e3799;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    min-height: 60px;
    min-width: 60px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.tinkeringImage {
    animation: tinker 12s infinite ease-in-out;
    transform-origin: center center;
    will-change: transform;
    transition: filter 0.3s ease-out;
}

@keyframes tinker {
    0% {
        transform: rotate(0deg) scale(1) translateY(0px);
    }

    25% {
        transform: rotate(3deg) scale(1.02) translateY(-5px);
    }

    50% {
        transform: rotate(-2deg) scale(0.98) translateY(3px);
    }

    75% {
        transform: rotate(2deg) scale(1.01) translateY(-3px);
    }

    100% {
        transform: rotate(0deg) scale(1) translateY(0px);
    }
}

.tinkeringImage:hover {
    /* Instead of changing animation duration, we'll pause it */
    animation-play-state: paused;
    filter: brightness(1.1);
}