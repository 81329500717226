.horizontal-slider {
    width: 200px;
    height: 25px;
    position: relative;
    background-color: transparent;
}

.example-thumb {
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: #1e3799;
    color: white;
    border-radius: 50%;
    cursor: grab;
    position: absolute;
}

.example-thumb:hover {
    background-color: #374fb0;
}

.example-track {
    position: absolute;
    background-color: lightgrey;
    height: 5px;
    top: 10px;
}

label {
    margin-left: 8px;
}


.custom-checkbox {
    display: flex;
    align-items: center;
}

/* Hide the actual checkbox, but keep it accessible */
.custom-checkbox input {
    opacity: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* Custom checkbox styling */
.custom-checkbox span {
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: lightgrey;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.custom-checkbox span:hover {
    cursor: pointer;
}

/* When the checkbox is checked, change the background color */
.custom-checkbox input:checked+span {
    background-color: #1e3799;
    /* Bootstrap primary blue */
}

/* Optional: Icon or checkmark to show when checked. */
/* Adjust content: '\2713' (a checkmark) or use a custom icon */
.custom-checkbox input:checked+span::before {
    content: '';
    /* You can use a checkmark or an icon here */
    display: block;
    color: white;
    position: absolute;
    left: 5px;
    top: 2px;
}

.filters-container {
    background-color: white;
    /* Slightly darker background */
    border-radius: 8px;
    padding: 8px 16px 36px 16px;
    margin-bottom: 8px;
    overflow: hidden;
}

.filters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.filters-label {
    font-weight: bold;
    color: black;
}

/* Responsive design for larger screens */
@media (min-width: 768px) {
    .filters-container {
        max-height: none;
        /* Ensure it's fully visible on larger screens */
    }
}

.title-tbd {
    font-size: 24pt;
    text-align: left;
    font-weight: 700;
    color: black;
}

@media (max-width: 768px) {
    .title-tbd {
        font-size: 21pt;
        line-height: 1.3em;
        margin-bottom: 4px;
    }

    .shrink-on-mobile {
        transform: scale(0.9);
    }
}

.cool-buttons {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0px 8px;
    border-radius: 5px;
    color: black;
}

.cool-buttons:hover {
    cursor: pointer;
    opacity: 0.8;
    ;
}