.ConsultantPanel {
    background-color: white;
    width: 280px;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
    position: relative;
    padding-bottom: 72px;
    border: 1px solid rgb(215, 215, 225);
}

@media (max-width: 400px) {
    .ConsultantPanel {
        width: 100% !important;
    }
}


.ConsultantPanel:hover {
    cursor: pointer;
    transform: scale(1.015);
    /* Grow the panel by 3% */
}

.BottomBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    gap: 8px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    /* Add position relative for pseudo-element positioning */
    overflow: hidden;
    /* Ensure the pseudo-element does not overflow */

}

.BottomBar::after {
    content: "";
    position: absolute;
    /* top: 0; */
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #13266f, #1e3799);
    opacity: 0;
    transition: opacity 0.5s ease;
    /* Transition for opacity */
}

.BottomBar:hover::after {
    opacity: 1;
    /* Change opacity to 1 on hover */
}

.Reviews {
    background-color: white;
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 0px 6px;
    border-radius: 30px;
}

.Badge {
    background-color: white !important;
    text-transform: uppercase;
    font-size: 9pt;
    padding: 0px 8px !important;
    border-radius: 3px;
}

.BottomBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 991px) {}