.filterBar {
    width: 100%;
    z-index: 10000;
}

.filterContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: 'Source Sans Pro';
    flex-wrap: wrap;
}

.dropdownButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 12px 4px 16px;
    background-color: rgb(210, 210, 220);
    border-radius: 20px;
    border: 0px;
    font-weight: 600;
    transition: background-color 0.2s, border-color 0.2s;
}

.dropdownButton:hover {
    background-color: rgb(220, 220, 230);
}

.dropdownButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 4px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    z-index: 50;
    border: 1px solid rgb(210, 210, 220);
}

.dropdownItem {
    width: 100%;
    padding: 8px 16px;
    border: 0px;
    background-color: white;
    text-align: left;
    transition: background-color 0.2s;
}

.dropdownItem:hover {
    background-color: #f5f5f5;
}

.searchContainer {
    display: flex;
    flex: 1;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
}

.searchInputWrapper {
    position: relative;
    width: 256px;
}

.searchInputContainer {
    position: relative;
    display: flex;
    align-items: center;
}

.searchInput {
    width: 100%;
    padding: 4px 40px 4px 20px;
    border-radius: 30px;
    border: 0px;
    background-color: rgb(210, 210, 220);
    font-weight: 600;
}

.searchInput:focus {
    outline: none;
    border-color: #b3b3b3;
}

.searchIcon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
}

.buttonText {
    color: #404040;
}

.dropdownWrapper {
    position: relative;
}

.XButton {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    border-radius: 20px;
    border: 0px;
}

.XButton:hover {
    cursor: pointer;
    opacity: 0.7;
}

.dropdownButtonContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.clearDropdownButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.clearDropdownButton:hover {
    opacity: 0.7;
}

@media (max-width: 768px) {
    .filterContainer {
        transform-origin: 0;
        transform: scale(0.9);
    }

    .searchContainer {
        justify-content: flex-start !important;
    }
}

@media (max-width: 991px) {
    .filterContainer {
        font-size: 11pt;
        gap: 8px;
    }

    .searchContainer {
        justify-content: flex-start !important;
    }
}