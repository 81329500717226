.RightPane {
    padding: 32px;
    position: absolute;
    display: flex;
    width: calc(100% - 180px);
    margin: 0px 0px 0px 180px;
    height: 100%;
    background-color: rgb(235, 235, 245);
    overflow-y: scroll;
    overflow-x: visible;
}

.RightPaneZeroPadding {
    position: absolute;
    display: flex;
    width: calc(100% - 180px);
    margin: 0px 0px 0px 180px;
    height: 100%;
    background-color: rgb(235, 235, 245);
    overflow-y: scroll;
    overflow-x: visible;
}

.FlexMobileColumn {
    display: flex;
    align-items: center;
    margin-bottom: 8;
    width: 100%;
    justify-content: space-between;
}

.PermissionImage {
    height: 180px;
}

.FlexMobileFriendly {
    display: flex;
    width: '100%';
    justify-content: space-between;
}

.HoverButton:hover {
    cursor: pointer;
}

@media (max-width: 600px) {
    .RightPane {
        width: 100%;
        margin: 0;
        padding: 12px;
    }

    .RightPaneZeroPadding {
        width: 100%;
        margin: 0;
    }

    .FlexMobileColumn {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 4px;
    }

    .DisappearMobile {
        display: none !important;
    }

    .Scale80Mobile {
        transform: scale(0.8) !important;
        transform-origin: left;
    }

    .Scale80MobileCenter {
        transform: scale(0.8) !important;
        transform-origin: center;
    }

    .Scale90Mobile {
        transform: scale(0.9) !important;
        transform-origin: left;
    }

    .Scale90MobileCenter {
        transform: scale(0.85) !important;
        transform-origin: center;
    }

    .PermissionImage {
        height: 140px !important;
    }

    .DisappearNonMobile {
        display: flex !important;
    }

    .FlexMobileFriendly {
        width: '100%';
        display: flex;
        flex-direction: column;
        align-items: center;
    }


}


.DynamicPane {
    background-color: white;
    border-radius: 5px;
    margin: 12px 0px 12px 0px;
    padding: 16px;
    width: 100%;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    border: 1px solid rgb(215, 215, 225);
}

.DynamicPaneClickable {
    background-color: white;
    border-radius: 12px;
    margin: 12px 0px 12px 0px;
    padding: 16px;
    width: 100%;
}

.DynamicPaneClickable:hover {
    opacity: 0.67;
}

@media (max-width: 850px) {
    .SubscriptionMobileFriendly {
        width: '100%';
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .DynamicPane {
        padding: 12px;
    }
}



.ActionButton {
    background-color: #1e3799;
    color: white !important;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    font-size: 15pt;
    padding: 8px 16px 8px 16px;
    border-radius: 5px;
    border: none;
    margin-bottom: 8px;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.ActionButton:hover {
    opacity: 0.65;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(1.04);
}

.ActionButtonSlim {
    background-color: #1e3799;
    color: white;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    font-size: 13pt;
    padding: 3px 12px 3px 12px;
    border-radius: 5px;
    border: none;
    margin-bottom: 8px;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.ActionButtonSlim:hover {
    opacity: 0.65;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(1.04);
}



.Timer {
    background-color: rgb(255, 255, 255);
    margin: 0px 16px;
    border-radius: 50%;
    min-width: 81%;
    min-height: 81%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TimerText {
    font-weight: 700;
    color: #232323;
    margin: 0px;
}

.SkipBtn {
    text-align: center;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    background-color: white;
    margin: 0px 8px;
    min-width: 100px;
    color: rgb(79, 79, 79);
    padding: 2px 12px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid lightgrey;
}

.SkipBtnLowkey {
    text-align: center;
    font-weight: 600;
    font-family: 'Source Sans Pro';
    margin: 0px 8px;
    min-width: 100px;
    color: rgb(71, 71, 71);
    padding: 2px 12px;
    border-radius: 5px;
}

.SkipBtnLowkey:hover {
    background-color: rgba(255, 255, 255, 0.3);
    color: #282828;
    cursor: pointer;
}

.SkipBtn:hover {
    opacity: 0.7;
    cursor: pointer;
}




.SegmentedControlBackground {
    height: 40px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 5px;
    width: min-content;
    background-color: rgb(215, 215, 225);
}

.SegmentedControlBackgroundVertical {
    background-color: rgb(235, 235, 245);
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 5px;
    width: min-content;
    flex-direction: column;

}

.SegmentedControl {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    padding: 5px 12px;
    margin: 0;
    white-space: nowrap;

    border-radius: 30px;

    color: rgb(79, 79, 79);
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: medium;
    font-weight: 600;
}

.SegmentedControl:hover {
    cursor: pointer;
    color: #282828;
    background-color: transparent;
}

.SegmentedControlEnabled {
    display: flex;
    align-items: center;
    border: 0px;
    white-space: nowrap;
    justify-content: center;

    padding: 5px 12px;
    margin: 0;

    border-radius: 30px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    color: black;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: medium;
    font-weight: 600;
}


.RecordingButton {
    background-color: transparent;
    border-radius: 15px;
    margin: 8px 0px;
    padding: 4px 0px;
    border: 3px solid #1e3799;

    font-family: 'Source Sans Pro';
    font-size: large;
    font-weight: bold;
    color: #1e3799;
}

.RecordingButton:hover {
    background-color: rgba(30, 55, 153, 0.13);
}

.FinishButton {
    width: 140px;
    background-color: #1e3799;
    color: white;
    font-family: 'Source Sans Pro';
    font-size: large;
    font-weight: bold;
    border: none;
    border-radius: 12px;

}

.InputTextBox {
    background-color: rgb(245, 248, 252);
    border: 1px solid rgb(171, 176, 181);
    border-radius: 5px;
    padding: 0px 4px;
}

.Spinner {
    border: 10px solid transparent;
    border-top: 10px solid #1e3799;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    min-height: 60px;
    min-width: 60px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ViewAnswerButton {
    font-family: 'Source Sans Pro';
    font-size: 16pt;
    font-weight: bold;
    color: white;
    background-color: #1e3799;
    border-radius: 5px;
    padding: 6px 16px;
    margin-bottom: 0;
}

.ViewAnswerButton:hover {
    opacity: 0.8;
    cursor: pointer;
}

.EmojiHover:hover {
    opacity: 0.8;
    cursor: pointer;
    /* background-color: rgb(230, 230, 240); */
    border: 1px solid lightgrey;
}

.LightButton {
    padding: 4px 12px;
    color: #1e3799;
    display: inline-block;
    font-weight: bold;
    background-color: #e8edff;
    border-radius: 20px;
    text-align: center;
    border: 0px;
}

/* WIDELY USED BUTTONS */

.LightButton:hover {
    background-color: #c0ceff;
}

.LightButtonLarge {
    padding: 8px 16px;
    color: #1e3799;
    display: inline-block;
    font-weight: bold;
    background-color: #e8edff;
    text-align: center;
    border-radius: 20px;
}

.LightButtonLarge:hover {
    background-color: #c0ceff;
}

.BackButton {
    color: #303030;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    border-radius: 40px;
    padding: 2px 12px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 0px;
}

.BackButton:hover {
    background-color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
}