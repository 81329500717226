.TopRow {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    padding: 32px;
    background: linear-gradient(to right, rgb(249, 250, 251), rgb(243, 244, 246));
    border-radius: 16px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.ProfileImage {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.ProfileImage:hover {
    transform: translateY(-4px);
}

.ConsultantInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.StatusBadges {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.Badge {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.CountryBadge {
    composes: Badge;
    background-color: black;
    color: white;
}

.LastActiveBadge {
    composes: Badge;
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(8px);
}

.Package {
    background: white;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid rgb(229, 231, 235);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.Package:hover {
    cursor: pointer;
    border-color: #1e3799;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.PackageContent {
    flex: 1;
}

.PackageMeta {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
}

.PackageTag {
    composes: Badge;
    background-color: rgba(37, 99, 235, 0.1);
    color: #1e3799;
}

.SectionCard {
    background: white;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    font-family: 'Source Sans Pro';
}

.SectionCard:hover {
    transform: translateY(-2px);
}

.SectionTitle {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 24px;
    color: #111827;
}

.VoucherCard {
    background: linear-gradient(135deg, #ecfdf5, #d1fae5);
    border: 1px solid #059669;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.1);
    flex-grow: 1;
}

@media (max-width: 991px) {
    .TopRow {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 24px;
    }

    .StatusBadges {
        justify-content: center;
    }

    .ProfileImage {
        width: 180px;
        height: 180px;
    }

    .Package {
        padding: 16px;
    }

    .PackageMeta {
        flex-wrap: wrap;
    }
}

.TimelineContainer {
    position: relative;
    padding-left: 32px;
    margin-left: 16px;
}

.TimelineContainer::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background: linear-gradient(to bottom, #e5e7eb 0%, #1e3799 100%);
    border-radius: 1px;
}

.TimelineItem {
    position: relative;
    padding: 16px 0;
    display: flex;
    gap: 24px;
    align-items: flex-start;
}

.TimelineItem::before {
    content: '';
    position: absolute;
    left: -37px;
    top: 24px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #1e3799;
    border: 3px solid white;
    box-shadow: 0 0 0 2px #1e3799;
    transition: all 0.2s ease;
}

.TimelineItem:hover::before {
    transform: scale(1.2);
    background: white;
}

.TimelineDate {
    min-width: 110px;
    font-weight: 600;
    color: #1e3799;
    padding: 4px 12px;
    background-color: rgba(37, 99, 235, 0.1);
    border-radius: 6px;
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.TimelineContent {
    flex: 1;
    background: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.TimelineItem:hover .TimelineContent {
    transform: translateX(4px);
    border-color: #1e3799;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.TimelineItem:hover .TimelineDate {
    background-color: #1e3799;
    color: white;
}

.ReviewCard {
    background-color: white;
    width: 100%;
    padding: 16px;
    border: 1px solid rgb(220, 220, 230);
    border-radius: 5px;
}

@media (max-width: 640px) {
    .TimelineContainer {
        padding-left: 24px;
        margin-left: 8px;
    }

    .TimelineItem {
        flex-direction: column;
        gap: 8px;
    }

    .TimelineDate {
        min-width: unset;
        width: fit-content;
    }

    .TimelineItem::before {
        left: -29px;
    }
}