.DropDownMenuText {
    font-family: 'Source Sans Pro';
    color: black !important;
}

.DropDownMenuText:hover {
    opacity: 0.8;
    cursor: pointer;
}

.HoverImage:hover {
    cursor: pointer;
}

.ProductText .dropdown-toggle {
    color: black !important;
    font-weight: bold !important;
}