.LabelTextSmall {
    font-family: 'Source Sans Pro';
    color: #282828;
    margin: 0;
    font-size: small;
    font-weight: 600;
    text-transform: uppercase;
}

.LabelText {
    font-family: 'Source Sans Pro';
    color: #282828;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
}

.RateOnlyDiv {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: white;
    font-family: 'Source Sans Pro';
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 2px 12px;
    border-radius: 20px;
}

.RateOnlyDivWithText {
    align-items: center;
    /* justify-content: start; */
    overflow: hidden;
    background-color: white;
    font-family: 'Source Sans Pro';
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    border-radius: 20px;
}

.AverageOnlyDiv {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: 'Source Sans Pro';
    display: flex;
    gap: 8px;
    padding: 2px 12px;
    border-radius: 20px;
}

.TextArea {
    margin-top: 12px;
    align-self: flex-start;
    width: 330px;
    height: 100px;
    background-color: rgb(245, 245, 255);
    border: 1px solid rgb(220, 220, 230);
    padding: 8px;
    border-radius: 5px;
}


.CharactersLeft {
    font-size: small;
    color: gray;
    align-self: end;
    margin-bottom: 12px;
}