.ReferrerBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 40px;
    border-radius: 5px;
    padding-top: 4px;
    border: 1px solid rgb(235, 235, 245);
    cursor: pointer;
}

.ReferrerBtnSelected {
    display: flex;
    /* box-shadow: 0px 3px 3px #1e379933; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 40px;
    border-radius: 5px;
    background-color: #1e379911;
    padding-top: 4px;
    color: #1e3799;
    cursor: pointer;
    border: 2px solid #1e3799;
}

.ReferrerBtnText {
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 10pt;
    font-family: 'Source Sans Pro';
}

.tinkeringImage {
    animation: tinker 12s infinite ease-in-out;
    transform-origin: center center;
    will-change: transform;
    transition: filter 0.3s ease-out;
}

@keyframes tinker {
    0% {
        transform: rotate(0deg) scale(1) translateY(0px);
    }

    25% {
        transform: rotate(3deg) scale(1.02) translateY(-5px);
    }

    50% {
        transform: rotate(-2deg) scale(0.98) translateY(3px);
    }

    75% {
        transform: rotate(2deg) scale(1.01) translateY(-3px);
    }

    100% {
        transform: rotate(0deg) scale(1) translateY(0px);
    }
}

.tinkeringImage:hover {
    /* Instead of changing animation duration, we'll pause it */
    animation-play-state: paused;
    filter: brightness(1.1);
}

/* Base class styles remain the same */
.ImageCenteredBG {
    overflow: hidden;
    /* Ensure stars don't overflow */
}