.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans Pro';
}

.header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.mainContent {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.listPane {
    width: 320px;
    border-right: 1px solid #e5e7eb;
    background-color: #f9fafb;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.applicationsList {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.applicationCard {
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    cursor: pointer;
    transition: all 0.2s;
}

.applicationCard:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-color: #bfdbfe;
}

.applicationCardSelected {
    border-color: #3b82f6;
    background-color: #eff6ff;
}

.statusBadge {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-weight: 600;
}

.statusPending {
    background-color: #fef3c7;
    color: #92400e;
}

.statusApproved {
    background-color: #d1fae5;
    color: #065f46;
}

.statusApprovedLive {
    background-color: #d1fae5;
    border: 2px solid #065f46;
    color: #065f46;
}

.statusRejected {
    background-color: #fee2e2;
    color: #991b1b;
}

.statusMoreInfo {
    background-color: #fad5b2;
    color: #922f0e;
}

.detailPane {
    flex: 1;
    overflow-y: auto;
    background-color: white;
    padding: 2rem;
}

.emptyState {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6b7280;
}

.documentSection {
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
}

.actionButtons {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    border-top: 1px solid #e5e7eb;
    padding-top: 1rem;
}

.sectionTitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: #6b7280;
    margin: 1rem 0 0.5rem 1rem;
}

.documentCard {
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    background-color: white;
}

.documentPreview {
    aspect-ratio: 4/3;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9fafb;
}

.documentImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pdfPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f3f4f6;
    color: #6b7280;
    font-size: 0.875rem;
}