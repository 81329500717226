.StandardLight {
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgb(235, 235, 245);
    font-family: 'Source Sans Pro';
    padding: 16px;
    overflow: hidden;
    color: black;
}

.StandardDark {
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgb(235, 235, 245);
    font-family: 'Source Sans Pro';
    padding: 8px;
    overflow: hidden;
    color: white;
}

.RowDiv {
    background-color: white;
    font-family: 'Source Sans Pro';
    padding: 8px;
    overflow: hidden;
    color: black;
}

.RowDiv:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

.Hoverable:hover {
    cursor: pointer;
    opacity: 0.9;
}

/* Pills */

.PillOutline {
    font-family: 'Source Sans Pro';
    color: black;
    margin: 0px;
    padding: 0px 12px;
    border: 2px solid black;
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.PillLight {
    font-family: 'Source Sans Pro';
    color: black;
    margin: 0px;
    padding: 0px 12px;
    background-color: rgb(235, 235, 245);
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.PillDark {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: black;
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.PillGreen {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #0f9346;
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.PillRed {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #e74c3c;
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.PillBlue {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #2980b9;
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.PillRainbow {
    font-family: 'Source Sans Pro';
    background: linear-gradient(to right, rgba(0, 0, 0, 0), teal), linear-gradient(to right, rgba(255, 0, 100, .3), rgba(255, 100, 127, .2)), linear-gradient(to top right, red, rgba(0, 0, 0, 0)), radial-gradient(closest-corner at 20% 80%, yellow, blue);
    border-radius: '0px 15px 15px 0px';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.PillBlueGradient {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background: linear-gradient(to right, navy, #2980b9);
    border-radius: 30px;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

/* Square Pills */


.SquarePillOutline {
    font-family: 'Source Sans Pro';
    color: black;
    margin: 0px;
    padding: 0px 12px;
    border: 2px solid black;
    display: flex;
    gap: 6px;
    border-radius: 3px;
    align-items: center;
    overflow: hidden;
}

.SquarePillLight {
    font-family: 'Source Sans Pro';
    color: black;
    margin: 0px;
    padding: 0px 12px;
    background-color: rgb(235, 235, 245);
    display: flex;
    gap: 6px;
    border-radius: 3px;
    align-items: center;
    overflow: hidden;
}

.SquarePillDark {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: black;
    display: flex;
    gap: 6px;
    border-radius: 3px;
    align-items: center;
    overflow: hidden;
}

.SquarePillGreen {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #0f9346;
    display: flex;
    gap: 6px;
    border-radius: 3px;
    align-items: center;
    overflow: hidden;
}

.SquarePillRed {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #e74c3c;
    display: flex;
    gap: 6px;
    border-radius: 3px;
    align-items: center;
    overflow: hidden;
}

.SquarePillBlue {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background-color: #2980b9;
    display: flex;
    gap: 6px;
    border-radius: 3px;
    align-items: center;
    overflow: hidden;
}

.SquarePillRainbow {
    font-family: 'Source Sans Pro';
    background: linear-gradient(to right, rgba(0, 0, 0, 0), teal), linear-gradient(to right, rgba(255, 0, 100, .3), rgba(255, 100, 127, .2)), linear-gradient(to top right, red, rgba(0, 0, 0, 0)), radial-gradient(closest-corner at 20% 80%, yellow, blue);
    border-radius: '0px 15px 15px 0px';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    display: flex;
    border-radius: 3px;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}

.SquarePillBlueGradient {
    font-family: 'Source Sans Pro';
    color: white;
    margin: 0px;
    padding: 0px 12px;
    background: linear-gradient(to right, navy, #2980b9);
    display: flex;
    border-radius: 3px;
    gap: 6px;
    align-items: center;
    overflow: hidden;
}


/* Spacers */

.VerticalSpacer8 {
    height: 8px;
}

.VerticalSpacer12 {
    height: 12px;
}

.VerticalSpacer16 {
    height: 16px;
}